.homePageDashboardWrapper {
  /* flex: 1;
  display: flex; */
  height: 100%;
  width: 100%;
}

.homePageDashboardWrapper .ant-tabs {
  height: 100%;
}

.homePageDashboardWrapper .ant-tabs-content {
  height: 100%;
}

.ant-tabs-small .ant-tabs-tab {
  padding: 8px 0;
}

/* .ant-tabs-nav .ant-tabs-tab-active {
   background-color: #FF9E28; 
  color: white;
} */

.ant-tabs-nav .ant-tabs-tab {
  padding: 10px 10px;
  margin: 0 0;
  font-size: 14px;
}

.ant-tabs-nav-container {
  font-size: 10px;
}

.ant-tabs-bar {
  margin: 0 0 8px 0;
  /*color: white;*/
}

.ant-tabs-ink-bar {
  background-color: #FF9E28;
}

.Resizer {
  background: #000;
  opacity: .2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 13px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 13px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}