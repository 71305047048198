.scoreDashboardWrapper {
  /* flex: 1;
  display: flex; */
  height: 100%;
  width: 100%;
}

.scoreDashboardWrapper .ant-tabs {
  height: 100%;
}

.scoreDashboardWrapper .ant-tabs-content {
  height: 100%;
}

.scoreDashboardWrapper .ant-tabs-small .ant-tabs-tab {
  padding: 8px 0;
}

.scoreDashboardWrapper .ant-tabs-nav .ant-tabs-tab-active {
  /* background-color: #FF9E28; */
  color: white;
}

.userMangerDashboardWrapper .ant-tabs-nav .ant-tabs-tab {
  padding: 10px 10px;
  margin: 0 0;
  font-size: 14px;
}

.userMangerDashboardWrapper .ant-tabs-nav-container {
  font-size: 10px;
}

.userMangerDashboardWrapper .ant-tabs-bar {
  margin: 0 0 8px 0;
  color: white;
}

.userMangerDashboardWrapper .ant-tabs-ink-bar {
  background-color: #FF9E28;
}