.number {
  text-align: right;
  font-weight: bold;
}

.number-positive {
  text-align: right;
  color: #16ab39;
  font-weight: bold;
  font-style: italic;
}

.number-negative {
  text-align: right;
  color: red;
  font-weight: bold;
  font-style: italic;
}

.number-zero {
  text-align: right;
  font-weight: bold;
  font-style: italic;
}

.status-fail {
  font-weight: 700;
  font-style: italic; 
  color: crimson;
}

.status-skip {
  font-weight: 700;
  font-style: italic; 
  color: #ff5500;
}

.status-pass {
  font-weight: 700;
  font-style: italic; 
  color: #87d068;
}

.status-warn {
  font-weight: 700;
  font-style: italic; 
  color: #eb9234;
}

.status-na {
  font-weight: 700;
  font-style: italic;
  color: grey;
}

.long {
  color: #16ab39;
  font-weight: bold;
  font-style: italic;
}

.short {
  color: red;
  font-weight: bold;
  font-style: italic;
}

.flat {
  color: rosybrown;
  font-weight: bold;
}

.orderType-mkt {
  color: #FF9E28;
  font-weight: bold;
  font-style: italic;
}

.orderType-lmt {
  color: steelblue;
  font-weight: bold;
  font-style: italic;
}

.timeInForce-day {
  color: steelblue;
  font-weight: bold;
  font-style: italic;
}

.timeInForce-gtc {
  color: #87d068;
  font-weight: bold;
  font-style: italic;
}

.keyword {
  /* color: rosybrown; */
  color: #FF9E28;
  font-weight: bold;
}

.error-keyword {
  color: #9F3A38;
  font-weight: bold;
}

.warning-keyword {
  color: #573A08;
  font-weight: bold;
}

.ui.message .content {
  font-weight: bolder;
}

.ag-floating-filter-input {
  background: #FF9E28 !important;
  color: black !important;
  font-weight: bold !important;
  border-color: transparent !important;
}

.ag-header-cell {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.ag-floating-filter-body {
  width: 100% !important;
}

/* Disable the floating filter */
.ag-floating-filter-button {
  display: none !important;
}

.ag-table-tooltip {
  position: absolute;
  width: 500px;
  height: 300px;
  border: 1px solid cornflowerblue;
  overflow: hidden;
  pointer-events: none;
  transition: opacity 1s;
}

.ag-table-tooltip.ag-tooltip-hiding {
  opacity: 0;
}

.comment {
  text-align: left;
  color: darkcyan;
  font-weight: 600;
  font-style: italic;
}

.non-number {
  text-align: left;
  font-weight: 600;
}

#root .ant-calendar-picker .ant-input {
  background-color: #FF9E28;
}

#root .ant-calendar-picker {
  margin-top: 3px;
  margin-bottom: 3px;
  margin-right: 5px;
  width: 120px;
}

#root .ant-switch {
  background-color: grey;
  margin-right: 5px;
}

#root .ant-switch-checked {
  background-color: #FF9E28;
  margin-right: 5px;
}
 
#root .ant-switch-inner {
  color: rgba(0, 0, 0, 0.65);
  font-weight: 600;
}

.horizontal-nav-bar {
  border-radius: 0 !important;
}

.horizontal-nav-bar .item {
  padding: 0.5em 1.1em !important;
}

body {
  /* background-color: #ECF0F1; */
  background-color: #2d3436 !important;
}

#root {
  height: 100%;
}

.box {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.box .row.header {
  flex: 0 1 auto;
  /* The above is shorthand for:
  flex-grow: 0,
  flex-shrink: 1,
  flex-basis: auto
  */
}

.box ::-webkit-scrollbar-corner {
  background:#1B1C1D;
}

.box .row.content {
  flex: 1 1 auto;
  padding: 2px;
  height: 100%;
  background-color:#2d3436;
  display: flex;
}

.box .row.statusbar {
  flex: 0 1 auto;
  color: white;
  /* background-color: #2d3436; */
  background-color: #8884d8;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  min-height: 30px;
}

.box .row.statusbar .statusInfo {
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 20px;
}

.operation-bar {
  text-align: right;
  background-color: #1B1C1D;
  margin-bottom: 2px;
}

.display-label {
  display: flex;
  align-items: center;
  justify-content: left;
  height: 27px;
}

.summary {
  text-align: center;
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 500;
  font-style: italic;
}

.widget {
  height: 100%;
  padding-bottom: 40px;
  border: 1.5px solid #636060;
}

.grid-wrapper {
  height: 100%;
}

.ui.modal > .actions > * {
  margin-left: 5px;
  margin-bottom: 5px;
}

nav {
  position: fixed;
  top: 45px;
  width: 100px;
  min-height: 100%;
  transition: transform 0.5s ease;
  z-index: 101;
}

nav.open {
  transform: translate(0, 0);
}

nav.closed {
  transform: translate(-125px, 0);
}

nav .menu {
  min-height: 100vh !important;
  border: none !important;
  border-radius: 0 !important;
}

nav .menu .item:last-child {
  border-radius: 0 !important;
  border-bottom: solid 1px rgba(0, 0, 0, 0.15) !important;
}

.flash{
  background-color: #f00;

  -webkit-animation-name: flash-animation;
  -webkit-animation-duration: 3s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;    
  -webkit-animation-play-state: running;
  -webkit-animation-direction: alternate;
  
  animation-name: flash-animation;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;    
  animation-play-state: running;
  animation-direction: alternate;
}

@-webkit-keyframes flash-animation {
  0%     {background-color:black;}
  100.0%  {background-color:red;}
}

@keyframes flash-animation {
  0%     {background-color:black;}
  100.0%  {background-color:red;}
}

.warn-flash{
  background-color: #FF9E28;

  -webkit-animation-name: warn-flash-animation;
  -webkit-animation-duration: 3s;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;    
  -webkit-animation-play-state: running;
  -webkit-animation-direction: alternate;
  
  animation-name: warn-flash-animation;
  animation-duration: 3s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;    
  animation-play-state: running;
  animation-direction: alternate;
}

@-webkit-keyframes warn-flash-animation {
  0%     {background-color:black;}
  100.0%  {background-color:#FF9E28;}
}

@keyframes warn-flash-animation {
  0%     {background-color:black;}
  100.0%  {background-color:#FF9E28;}
}

/* #root .ant-picker{
  background-color:#FF9E28;
}

#root .ant-input{
  background-color:#FF9E28;
}

#root .ant-select .ant-select-selector{
  background-color:#FF9E28;
}

#root .ant-input-search{
  background-color:#FF9E28;
}

#root .ant-input-number{
  background-color:#FF9E28;
} */

#root .ant-input-search .ant-input{
  padding: 0px;
  margin: 0px;
}

#root input::-webkit-input-placeholder{
  color:gray;
}

#root input::-moz-placeholder{   /* Mozilla Firefox 19+ */
  color:gray;
}
#root input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
  color:gray;
}
#root input:-ms-input-placeholder{  /* Internet Explorer 10-11 */ 
  color:gray;
}